import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import LoginPage from "./login/containers/LoginPage";
import LogoutPage from "./login/containers/LogoutPage";
import ResetPasswordPage from "./login/containers/ResetPasswordPage";
import NoMatch from "./login/containers/404";
import SubUserHome from "./login/containers/SubUser/Home";
import NewSubUser from "./login/containers/SubUser/NewSubUser";
import EditSubUser from "./login/containers/SubUser/EditSubUser";
import VerifySubUser from "./login/containers/SubUser/VerifySubUser";
import UpdateProfile from "./login/containers/SubUser/UpdateProfile";
import Activate from "./login/containers/SubUser/Activate";
import LoginAsPage from "./login/containers/LoginAsPage";

export default () => {
  return <BrowserRouter>
    <Routes>
      <Route index element={<LoginPage />} />
      <Route path="/log-subuser-as" element={<LoginAsPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="/sub-user" element={<SubUserHome />} />
      <Route path="/sub-user/new" element={<NewSubUser />} />
      <Route path="/sub-user/edit" element={<EditSubUser />} />
      <Route path="/sub-user/verify" element={<VerifySubUser />} />
      <Route path="/sub-user/update-profile" element={<UpdateProfile />} />
      <Route path="/sub-user/activate" element={<Activate />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
}