import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated, isSubUser } from "../../actions/authActions";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";
import { Text, Icon, palette } from "@capps/laddition-storybook";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { I18n } from "react-redux-i18n";
import SecretCode from "./components/SecretCode";
import {transformErrorMessage} from "../../../utils/errorMessage";

function ActivateAccount(props) {

  const { state } = useLocation();
  const navigate = useNavigate();

  if(!state) {
    props.addFlashMessage({
      type: "error",
      text: I18n.t("errorUnknown"),
    });
    setTimeout(() => navigate('/'), 1200);
    return <div></div>;
  }

  if (!props.isAuthenticated() && !props.isSubUser()) {
    props.addFlashMessage({
      type: "error",
      text: I18n.t("notAllowedPage"),
    });
    setTimeout(() => navigate('/'), 1500);
    return <div></div>;
  }

  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    for (let input of document.getElementById("secret-code").getElementsByTagName("input")) {
      input.addEventListener('click', () => {
        if(isError) {
          setIsError(false);
        }
      });
    }
  }, [isError]);



  const submitActivation = (code) => {
    return axios.put('/v2/api/sub-user/secret/verify', {
      secret: code,
      sub_user_id: state.sub_user_id,
    })
      .then((response)=> {
        const { update } = response.data;
        if (!update) {
          props.addFlashMessage({
            type: "success",
            text: I18n.t("subUser.update.success"),
          });
          navigate("/logout");
          return;
        }
        props.addFlashMessage({
          type: "success",
          text: I18n.t('subUser.activate.success'),
        });
        setIsValid(true);
        setTimeout(() => navigate('/sub-user/update-profile'), 2000);
      })
      .catch(() => {
        setIsError(true);
      });
  }

  const resendCode = () => {
    return axios.put('/v2/api/sub-user/secret/resend', {
      sub_user_id: state.sub_user_id,
    })
      .then(() => {
        props.addFlashMessage({
          type: "success",
          text: I18n.t('subUser.activate.resend.success'),
        });
      })
        .catch(({ response }) => {
          const { code, message } = response.data;
          const text = code ? transformErrorMessage(`subUser.errorCode.${code}`, message) : I18n.t('subUser.activate.resend.error');
          props.addFlashMessage({ type: 'error', text });
        })
  }

  const titleText = isValid ? 'subUser.activate.valid.title' : (isError ? 'subUser.activate.error.title' : 'subUser.activate.waiting.title');
  const infoText = isValid ?  I18n.t('subUser.activate.valid.info') : I18n.t('subUser.activate.waiting.info');

  return <div className="container__form flex flex-col align-center">
      <div className="sub-user-secret-loader">
        { isError ? <Icon name="invalid" color="#e84249" height={100} width={100} /> : null }
        { isValid ? <Icon name="check" color="#7cc4ac" height={100} width={100} /> : null }
        { !isValid && !isError && <Icon name="key" color="white" height={100} width={100} /> }
      </div>
      <Text variant="h2" color="white" className="text-center mb-20">{ I18n.t(titleText) }</Text>
      <SecretCode isError={isError} codeLength={6} onComplete={submitActivation} />
      <Text className="sub-user-secret-info" color="white" type="info">{ infoText }</Text>
      {
        !isValid &&
          <div onClick={() => resendCode()}>
            <Text type="info" color="secondary" className="sub-user-secret-resend">
              { I18n.t('subUser.activate.resend.label') } <Icon name="chevronright" color="secondary" />
            </Text>
          </div>
      }
  </div>;

}

export default connect(null, { isAuthenticated, addFlashMessage, isSubUser })(ActivateAccount);