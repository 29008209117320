import { I18n } from "react-redux-i18n";

export function transformErrorMessage(code, defaultMessage = null, templateData = null) {
  const message = I18n.t(code, defaultMessage);

  if (message === code) {
    return defaultMessage || I18n.t("errorCode.UNKNOWN_ERROR");
  }

  if (templateData) {
    return message.replace(/{{\s*(\w+)\s*}}/g, (match, key) => {
      return templateData[key];
    });
  }

  return message;
}