import {
  SET_CURRENT_USER,
  SET_RESET_EMAIL_SEND,
  SET_RESET_TOKEN,
  SET_PASSWORD_CHANGED,
  SET_SERVICES, SET_IS_SUB_USER
} from "../constant";
import isEmpty from "lodash/isEmpty";

const initialState = {
  isAuthenticated: false,
  resetEmailSend: false,
  resetPasswordToken: "",
  passwordChanged: false,
  isSubUser: false,
  user: {},
  services: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    case SET_RESET_EMAIL_SEND:
      return {
        resetEmailSend: action.success,
        error: action.error
      };
    case SET_RESET_TOKEN:
      return {
        resetPasswordToken: action.token
      };
    case SET_PASSWORD_CHANGED:
      return {
        passwordChanged: action.success
      };
    case SET_SERVICES:
      return {
        services: action.services
      };
    case SET_IS_SUB_USER:
      return {
        isSubUser: action.isSubUser
      }
    default:
      return state;
  }
};
