import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { connect } from "react-redux";
import { logout } from "../actions/authActions";
import { I18n } from "react-redux-i18n";
import {Text} from "@capps/laddition-storybook";
import { useNavigate } from "react-router-dom";

export function LogoutView(props) {


  const { logout } = props;
  const navigate = useNavigate();


  useEffect(() => {
    logout().then(() => navigate('/'));
  }, []);

    return <Text type="title" variant="h2" color="white" weight="bold" className="text-center">
      {I18n.t("logout.title")}{" "}
      <div className="wavy-loader">
        <span />
        <span />
        <span />
        <span />
      </div>
    </Text>;

}

LogoutView.contextTypes = {
  router: PropTypes.func.isRequired
};

export default connect(
  null,
  { logout }
)(LogoutView);
