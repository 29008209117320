let urlReporting = "http://localhost:49611/";

if (window.location && window.location.hostname === "auth.laddit.io") {
  urlReporting = "https://reporting.laddit.io/";
} else if (
  window.location &&
  window.location.hostname === "dev.auth-api.laddition.com"
) {
  urlReporting = "https://dev.reporting.laddition.com/";
} else if (
  window.location &&
  window.location.hostname === "auth.laddition.com"
) {
  urlReporting = "https://reporting.laddition.com/";
}

export default {
  urlReporting
};
