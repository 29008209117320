import React, {useEffect, useMemo, useState} from "react";
import { value } from "lodash/seq";
import { Switch, Text } from "@capps/laddition-storybook";

export function SubUserServices(props) {

    const { servicesList, onClick, activeServices, subUserId } = props;
    const [activeServicesList, setActive] = useState(activeServices ?? []);

    useMemo(() => {
        if (activeServices?.length) {
            setActive(activeServices);
        }
    }, [activeServices]);

    const switchClick = (value, checked) => {
        const listCopy = activeServicesList ? activeServicesList : [];
        const serviceId = +value;

        const indexActive = listCopy.indexOf(serviceId);
        const isActive = indexActive < 0;

        if (isActive && !checked) {
            return;
        }

        isActive ? listCopy.push(serviceId) : delete listCopy[indexActive];
        setActive(listCopy);

        onClick(listCopy.filter((value) => !!value));
    }


    return <div className="sub-user-services">
        {
            servicesList.map((service, index) => {
                return <div key={index} className="sub-user-switch-item">
                    <Text className="mb-0">{service.title}</Text>
                    <Switch
                        name={service.name}
                        onChange={(checked) => switchClick(service.id, checked) }
                        size="medium"
                        checked={activeServicesList.includes(service.id)}
                        inputArgs={{value: service.id }}
                    />
                </div>
            })
        }
    </div>
}
