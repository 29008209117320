import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, isSubUser } from '../actions/authActions';
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import config from '../../utils/config';
import getAllUrlParams from '../../utils/getUrlParams';
import { I18n } from 'react-redux-i18n';
import { addFlashMessage } from '../../flashMessage/actions/flashMessages';
import { Text, Input, Button, Loader } from "@capps/laddition-storybook";

import Feed from '../../common/Feed/Feed';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { isValidMail } from "../../utils/toolbox";

function validateInput(data) {
	let errors = {};

	if (Validator.isEmpty(data.identifier)) {
		errors.identifier = I18n.t('errorIdentifierEmpty');
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = I18n.t('errorPasswordEmpty');
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

function LoginForm(props) {
	const { scope } = getAllUrlParams();

	const [identifier, setIdentifier] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState('loginChanged');
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();

	const isSubUser = props.isSubUser();
	const { refreshToken, dbStatus } = localStorage;
	const [isLogged, setLogged] = useState(!!refreshToken);

	useEffect(() => {
		initialize();
	}, []);
	useEffect(() => {
		if (isSubUser) {
			setLoading(true);
			checkSubUserProfileValid()
				.then(({ data }) => {
					const { success } = data;
					const navigateUrl = success ? '/log-subuser-as' : '/sub-user/update-profile';
					navigate(navigateUrl, { state: { scope }})
				})
					.finally(() => setLoading(false));
		}
	}, []);

	const initialize = async () => {
		if (!refreshToken || isSubUser) {
			return;
		}

		const params = {
			token: localStorage.refreshToken,
			scope: scope,
		};

		try {
			const { data } = await axios.post('/api/auth/checkaccess', params);
			const isDbStatusOk = data.dbStatus || data.dbStatus === true || data.dbStatus === 'online';

			if (!!dbStatus && isDbStatusOk) {
				window.localStorage.removeItem('dbStatus');
				window.location.reload();
			} else if (!isDbStatusOk) {
				window.localStorage.dbStatus = data.dbStatus;
				window.location.reload();
			}

			const urlBack = data.urlBack || config.urlReporting;
			const url = data.cookies ? urlBack : `${urlBack}?refreshToken=${refreshToken}`;
			window.localStorage.removeItem('dbStatus');
			window.location.replace(url);

		} catch (err) {
			setLogged(false);
		}
	}

	function isValid() {
		const { errors, isValid } = validateInput({ identifier, password });
		if (!isValid) {
			setErrors(errors);
		}

		return isValid;
	}

	const onSubmit = (e) => {
		e.preventDefault();
		if (isValid) {
			setErrors({});
			setLoading(true);
			props.login({ identifier, password }).then(
					res => {
						if (res && res.back.length > 0) {
							if (
									res.dbStatus &&
									(res.dbStatus === false ||
											res.dbStatus === 'offline' ||
											res.dbStatus === 'recovering' ||
											res.dbStatus === 'failed')
							) {
								window.localStorage.dbStatus = 'maintenance';
								window.location.reload();
							}
							if (res.back.length === 1) {
								window.location.replace(
										res.back[0].url + '?refreshToken=' + localStorage.refreshToken
								);
							} else {
								window.location.replace(
										config.urlReporting +
										'?refreshToken=' +
										localStorage.refreshToken
								);
							}
						}
						else if(res && !!res.back.url) {
							let redirectUrl = res.back.url;
							if(redirectUrl.includes(window.location.origin)){
								redirectUrl = redirectUrl.replace(window.location.origin, '');
								return navigate(redirectUrl);
							}
							const url = res.back.cookies ? redirectUrl : redirectUrl + '?refreshToken=' + localStorage.refreshToken;
							window.location.replace(url);
						}
						setLoading(false);
					},
					err => {
						setIdentifier('');
						setPassword('');
						setLoading(false);

						const error = err.response && err.response.data && err.response.data.error
										? err.response.data.error
										: I18n.t('resetPassword1.error');
						setErrors(error);

						let message = err.response && err.response.data && err.response.data.error
								? I18n.t('loginForm.invalidCredentials')
								: I18n.t('errorUnknown');
						if (err.data?.code) {
							const i18nMessage = I18n.t(`errorCode.${err.data.code}`);
							message = i18nMessage === err.data.code ? message : i18nMessage;
						}

						props.addFlashMessage({
							type: 'error',
							text: message,
						});
					}
			);
		}
	}

	function checkSubUserProfileValid() {
		return axios.get('/v2/api/sub-user/valid')
			.catch(() => {
				localStorage.clear();
				window.location.reload();
			});
	}

	const loginForm = (
			<div className="w-full sb-dark">
				<div className="container__form">
					<Text
							color="white"
							className="title"
							variant="h2"
					>
						{I18n.t('loginForm.title')}
					</Text>
					{ /** <Text type="info" color="white" className="navbar-description">
					 {I18n.t('loginForm.description')}
					 <span>{I18n.t('header.nameCompany')}</span>
					 </Text> **/}
					{/* <FlashMessagesList /> */}
					<form className="auth__form" onSubmit={onSubmit}>
						<Input
							label={I18n.t('loginForm.emailLabel')}
							placeholder={I18n.t('loginForm.emailLabel')}
							variant="dark"
							onChange={(e) => setIdentifier(e.target.value)}
							value={identifier}
							name="identifier"
							tyoe="email"
							required="required"
							id="identifier"
							className="auth__form__input"
							isValid={isValidMail(identifier)}
						/>
						<Input
							label={I18n.t('loginForm.passwordLabel')}
							placeholder={I18n.t('loginForm.passwordLabel')}
							variant="dark"
							type="password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							name="password"
							required="required"
							id="password"
							className="auth__form__input"
							isValid={password.length >= 8}
						/>
						<div className="form__buttons">
							<Button
									color="secondary"
									disabled={!(identifier !== '' && password !== '') || isLoading}
									label={I18n.t('loginForm.valid')}
							/>
							<Link to="/reset-password">
								<Button
										color="ldd-grey-100"
										className="mb-0"
										label={I18n.t('loginForm.forgotPassword')}
										variant="text"
										size="small"
								/>
							</Link>
						</div>
					</form>
				</div>
			</div>
	);

	const loginSuccess = (
			<div>
				<Text color="white" variant="h2" weight="bold" className="mb-20">{I18n.t('loginForm.successAuthentication')}</Text>
				<div className="loader">
					<Loader className="mx-auto" />
				</div>
			</div>
	);

	const loginSuccessMaintenance = <Feed name="maintenance" />;

	if (!isLogged) {
		return loginForm;
	}
	if (!isSubUser) {
		if (localStorage.dbStatus) {
			return loginSuccessMaintenance;
		}
		return loginSuccess;
	}
	return <></>;
}

LoginForm.propTypes = {
	login: PropTypes.func.isRequired,
};

export default connect(null, { login, addFlashMessage, isSubUser })(LoginForm);
