import fr from "./fr";
import es from "./es";
import esES from "./es-ES";
import ca from "./ca";
import caES from "./ca-ES";

export default {
  fr,
  es,
  "es-ES": esES,
  ca,
  "ca-ES": caES
};
