import React from 'react';
import LogoutView from '../components/LogoutView';
import {connect} from 'react-redux';
import {logout} from '../actions/authActions';

class LogoutPage extends React.Component {
    render() {
        return (
            <div className="container center">
                <LogoutView logout={logout}/>
            </div>
        );
    }
}


export default connect(null, {logout})(LogoutPage);
