import PropTypes from "prop-types";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function FlashMessage (props) {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  React.useEffect(() => {
    handleOpen();
    window.setTimeout(() => {
      handleClose();
    }, 4500);
  }, []);


  const { type, text } = props.message;

  return (
    <Snackbar
      open={open}
      TransitionComponent={TransitionUp}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": props.message.id
      }}
      message={<span id={props.message.id}>{text}</span>}
      className={type === "success" ? "successMessage" : "errorMessage"}
    />
  );
}

FlashMessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired
};

export default FlashMessage;
