import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { sendVerificationEmail } from "../actions/authActions";
import { addFlashMessage } from "../../flashMessage/actions/flashMessages";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import { I18n } from "react-redux-i18n";
import { Text, Input, Button } from "@capps/laddition-storybook";
import { useNavigate } from "react-router-dom";
import { isValidMail } from "../../utils/toolbox";

function validateInput(data) {
  let errors = {};

  if (Validator.isEmpty(data.identifier)) {
    errors.identifier = I18n.t("resetPassword1.empty");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

function ResetPasswordFormStep1(props) {

  const [identifier, setIdentifier] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [local, setLocal] = useState(navigator.language);

  const navigate = useNavigate();


  const isValid = () => {
    const { errors, isValid } = validateInput({ identifier });

    if (!isValid) {
      setErrors({ errors });
    }

    return isValid;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      setErrors((previous) => { return {} });
      setIsLoading(true);
      props.sendVerificationEmail({ identifier, local }).then(
        res => {
          if (res.success) {
            setErrors((previous) => { return {} });
            setIsLoading(false);
            if (identifier.indexOf("@laddition.com") === -1) {
              props.addFlashMessage({
                type: "success",
                text: I18n.t("resetPassword1.successVerification")
              });
            } else {
              props.addFlashMessage({
                type: "success",
                text: I18n.t("resetPassword1.successSupport")
              });
            }
            window.setTimeout(() => navigate('/'), 3000);
          } else {
            let message = "";
            message = I18n.t(`errorCode.${res.code}`) || I18n.t("resetPassword1.error");

            setErrors(() => { return { form: message } });
            setIsLoading(false);
            props.addFlashMessage({
              type: "error",
              text: message
            });
          }
        },
        err => {
          setErrors(() => { return { err } });
          setIsLoading(false);
          props.addFlashMessage({
            type: "error",
            text: err
          });
        }
      );
    }
  }

  const onChange = (e) => {
    const name = e.target.name;
    if(name === "identifier") {
      setIdentifier(e.target.value);
    }
  }


  return (
    <div className="w-full">
      <div className="container__form">
        <Text variant="h2" color="white" className="title sb-px-2">
          {I18n.t("resetPassword1.title")}
        </Text>
        <form className="auth__form sb-dark">
          <Input
              label={I18n.t("resetPassword1.emailLabel")}
              placeholder={I18n.t("resetPassword1.emailLabel")}
              onChange={onChange}
              value={identifier}
              name="identifier"
              required
              id="login-input"
              className="auth__form__input"
              isValid={isValidMail(identifier)}
          />
          <div className="form__buttons">
            <Button
              color="secondary"
              className="submitButton"
              disabled={!(identifier !== "" && isValidMail(identifier)) || isLoading}
              label={I18n.t("resetPassword1.submit")}
              onClick={(event) => onSubmit(event)}
            />
            <Button
              variant="text"
              size="small"
              color="ldd-grey-100"
              onClick={() => navigate("/")}
              label="Retour"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

ResetPasswordFormStep1.propTypes = {
  sendVerificationEmail: PropTypes.func.isRequired
};

export default connect(
  null,
  { sendVerificationEmail, addFlashMessage }
)(ResetPasswordFormStep1);
