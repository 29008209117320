
export function hasSpecialChar(str) {
    return /[!@#$%^&*()_+=[\]{};:|,.<>?-]+/.test(str);
}

export function hasNumeric(str) {
    return (str.match(/\d+/g) !== null);
}

export function hasLowerCase(str) {
    return (str.toUpperCase() !== str);
}

export function hasUpperCase(str) {
    return (str.toLowerCase() !== str);
}
