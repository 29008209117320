import React, {useEffect, useRef, useState} from "react";


export default function SecretCode(props) {

  const { isError, codeLength, onComplete, className } = props;
  const isLastFocusItem = useRef(false);
  const initialCode = new Array(codeLength).fill("");
  const [validCode, setValueCode] = useState(initialCode);
  const code = validCode.join("");
  let lastKeyPress = null;

  window.addEventListener('keydown', (e) => lastKeyPress = e.key);
  useEffect(() => {
    if (code.length === codeLength && isLastFocusItem.current) {
      submitCode();
    }
  }, [code]);

  useEffect(() => {
    if (isError) {
      setValueCode(initialCode);
    }
  }, [isError]);

  const handleChangeCode = (e) => {
    const isEnter = lastKeyPress === "Enter";

    if (isEnter) {
      submitCode();
    }


    const isDelete = lastKeyPress === "Backspace";
    if (!(lastKeyPress && (lastKeyPress.match(/^[0-9a-zA-Z]$/))) && !isDelete) {
      return;
    }
    const { id } = e.target;
    const inputPosition = +id.split('_')[1];

    const inputTarget = document.getElementById("secret-code").getElementsByTagName("input").item(inputPosition + (isDelete ? -1 : 1));

    setValueCode((prevState) => {
      const newState = [...prevState];
      newState[inputPosition] = isDelete ? "" : lastKeyPress.toUpperCase();
      return newState;
    });


    isLastFocusItem.current = (inputPosition+1) === codeLength;
    if(isLastFocusItem.current) {
      document.activeElement.blur();
    }

    if (inputTarget) {
      inputTarget.focus();
    }
  }

  const submitCode = () => {
    if (code.length === codeLength) {
      onComplete(code);
    }
  }

  return <div className="sub-user-secret-code" id="secret-code">
    {
      validCode.map((code, index) => {
        return <input type="text" className={isError ? "error" : null} id={`code_${index}`} onKeyUp={handleChangeCode} value={code} />
      })
    }
  </div>;
}