import { connect } from "react-redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifySubUser } from "../../actions/authActions";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";
import { useSearchParams } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import {transformErrorMessage} from "../../../utils/errorMessage";
function VerifySubUser(props) {
    const [queryParameters] = useSearchParams();
    const token = queryParameters.get("token");
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            let data;
            try {
                data = JSON.parse(atob(token));
                verifySubUser(data);
            } catch(_) {
                props.addFlashMessage({
                    type: "error",
                    text: I18n.t("errorUnknown"),
                });
                return navigate('/');
            }

        }
    }, []);


    const verifySubUser = async (data) => {
        try {
            const enabled = await props.verifySubUser(data);
            const urlRedirection = enabled ? '/sub-user/update-profile' : '/sub-user/activate';
            return navigate(urlRedirection, { state: { sub_user_id: data.sub_user_id } });
        } catch ({ response }) {
            const { code, message } = response.data;
            const errorCode = `subUser.errorCode.${code}`;
            const text = code ? transformErrorMessage(errorCode, message) : I18n.t('subUser.verify.error.400');
            props.addFlashMessage({ type: 'error', text });
            setTimeout(() => navigate('/logout'), 1500);
        }
    }


    return <div className="sub-user-home"></div>;
}

export default connect(null, { verifySubUser, addFlashMessage })(VerifySubUser);