import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  changePassword,
  checkResetTokenValidity
} from "../actions/authActions";
import { addFlashMessage } from "../../flashMessage/actions/flashMessages";
import getUrlParams from "../../utils/getUrlParams";
import {
  hasSpecialChar,
  hasNumeric,
  hasLowerCase,
  hasUpperCase
} from "../../utils/validator";
import { I18n } from "react-redux-i18n";
import PasswordHint from "../../common/Tooltip/PasswordHint/PasswordHint";
import { Text, Input, Button } from "@capps/laddition-storybook";
import { useNavigate } from "react-router-dom";

function validateInput(password, passwordVerification) {
  let errors = {};

  if (!password) {
    errors.password = I18n.t("resetPassword2.empty");
  }

  if (!passwordVerification) {
    errors.passwordVerification = I18n.t("resetPassword2.empty");
  }

  if (
    password.length >= 8 &&
    hasLowerCase(password) &&
    hasUpperCase(password) &&
    hasNumeric(password) &&
    hasSpecialChar(password)
  ) {
  } else {
    errors.password = I18n.t("resetPassword2.errorValidator");
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0
  };
}

function ResetPasswordFormStep2(props) {

  const getParams = getUrlParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordVerification, setPasswordVerification] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!!getParams.resetToken) {
      props.checkResetTokenValidity(getParams.resetToken).then(
        res => {
          if (!res.success) {
            const message = I18n.t(`errorCode.${res.code}`) || I18n.t("resetPassword2.error");
            props.addFlashMessage({
              type: "error",
              text: message,
            });
            navigate("/");
          } else {
            setResetToken(res.resetToken);
          }
        },
        err => {
          props.addFlashMessage({
            type: "error",
            text: err
          });
          navigate("/");
        }
      );
    }
  }, []);

  const checkPassword = () => {
    const { errors, isValid } = validateInput(password, passwordVerification);

    if (isValid && password !== passwordVerification) {
      errors.password = I18n.t("resetPassword2.unidentical");
      return false;
    }

    return isValid;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (checkPassword()) {
      setIsLoading(true);
      props.changePassword({ password, passwordVerification, resetToken }).then(
        () => {
          props.addFlashMessage({
            type: "success",
            text: I18n.t("resetPassword2.success")
          });
          setTimeout(() => navigate("/"), 3000);
        },
        err => {
          setErrors({ err });
          setIsLoading(false);
          props.addFlashMessage({
            type: "error",
            text: err
          });
        }
      );
    }
  }

  const onChange = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "passwordVerification") {
      setPasswordVerification(e.target.value);
    }
  }

  const isValidPassword = password.length >= 8 && hasUpperCase(password) && hasLowerCase(password) && hasNumeric(password) && hasSpecialChar(password);
  return (<div className="w-full">
    <div className="container__form">
      <Text variant="h4" className="title" color="white">{I18n.t("resetPassword2.title")}</Text>
      {/* <FlashMessagesList /> */}
      <form className="auth__form sb-dark" onSubmit={onSubmit}>
        <Input
          label={I18n.t("resetPassword2.passwordLabel")}
          placeholder={I18n.t("resetPassword2.passwordLabel")}
          type="password" onChange={onChange}
          value={password}
          name="password"
          required="required"
          id="password-input"
          isValid={isValidPassword}
        />
        <PasswordHint password={password} titleType="info" hintType="cta" className="mb-30" colorError="ldd-red-950"/>
        <Input
          label={I18n.t("resetPassword2.passwordConfirmLabel")}
          placeholder={I18n.t("resetPassword2.passwordConfirmLabel")}
          type="password"
          onChange={onChange}
          value={passwordVerification}
          name="passwordVerification"
          required="required"
          id="password-input-repeat"
          showError={!!passwordVerification && password !== passwordVerification}
          errorMessage={I18n.t("resetPassword2.unidentical")}
          isValid={password === passwordVerification && passwordVerification !== ""}
        />
        <div className="form__buttons">
          <Button
            type="contained"
            className="mt-0"
            disabled={
              !(
                password &&
                passwordVerification &&
                password === passwordVerification &&
                password.length >= 8 &&
                hasUpperCase(password) &&
                hasLowerCase(password) &&
                hasNumeric(password) &&
                hasSpecialChar(password)
              ) || isLoading
            }
            color="secondary"
          >
            {I18n.t("loginForm.valid")}
          </Button>
        </div>
      </form>
    </div>
  </div>);
}

ResetPasswordFormStep2.propTypes = {
  changePassword: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  checkResetTokenValidity: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    changePassword,
    addFlashMessage,
    checkResetTokenValidity
  }
)(ResetPasswordFormStep2);
