import { combineReducers } from "redux";

import flashMessages from "./flashMessage/reducers/flashMessages";
import auth from "./login/reducers/auth";
import { i18nReducer } from "react-redux-i18n";

export default combineReducers({
  flashMessages,
  auth,
  i18n: i18nReducer
});
