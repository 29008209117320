import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, Input } from "@capps/laddition-storybook";
import { isAuthenticated, isSubUser } from "../../actions/authActions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";
import { I18n } from "react-redux-i18n";
import PasswordHint from "../../../common/Tooltip/PasswordHint/PasswordHint";
import { SubUserForm } from "./components/SubUserForm";
import {useAnimate} from "framer-motion";

function UpdateProfile(props) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [scope, animate] = useAnimate();
    const [sidebarScope, animateSidebar] = useAnimate();

    // Force re-render to avoid auto-focus on Safari which breaks framer-motion animation
    const [_, setBlank] = useState(true);
    setTimeout(() => setBlank(false), 1);

    if (!props.isSubUser() || !props.isAuthenticated()) {
        navigate('/');
        return <div></div>;
    }

    const [body, setBody] = useState({
        lastname: "",
        firstname: "",
        company: "",
        password: "",
    });
    const [isValidFields, setValueFields] = useState({
        lastname: true,
        firstname: true,
        company: true,
        password: true,
    });

    const disabledSubmit = Object.values(body).some(field => field === "") || Object.values(isValidFields).includes(false);
    const passwordRegExpString = "(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)(?=\\S*\\W)\\S{8,}$";
    const passwordRegExp = new RegExp(passwordRegExpString);

    const updateField = (e) => {
        const { name, value } = e.target;

        if (Object.keys(body).includes(name)) {
            body[name] = value;
            const bodyForm = {...body};
            bodyForm[name] = value;
            setBody(bodyForm);

            const fieldValid = {...isValidFields};
            fieldValid[name] = name === "password" ? passwordRegExp.test(value) : !!value;
            setValueFields(fieldValid);
        }
    }
    const confirmUpdate = () => {
        const fields = Object.keys(body);
        for (let i = 0; i < fields.length; i++) {
            if(body[fields[i]] === "") {
                const fieldValid = {...isValidFields};
                fieldValid[fields[i]] = false;
                setValueFields(fieldValid);
                return;
            }
        }

        const params = {
            username: `${body.firstname} ${body.lastname}`,
            company: body.company,
            password: body.password,
            local_iso: "fr"
        }

        return axios.put("/v2/api/sub-user", params)
            .then(() => {
                props.addFlashMessage({
                    type: "success",
                    text: I18n.t("subUser.update.success"),
                });
                animateSidebar(sidebarScope.current, { opacity: 0 }, { duration: 0.3 });
                animate(scope.current, { x: "100%" }, { duration: 0.3 });
                return navigate("/logout");
            })
            .catch(() => {
                props.addFlashMessage({
                    type: "error",
                    text: "An error occured, tu connais",
                });
            });
    }


    return <>
        { !isLoading ? <SubUserForm
                backButton={false}
                sideTitle="subUser.update.side.title"
                sideDescription="subUser.update.side.description"
                formTitle="subUser.update.title"
                animateRef={scope}
                sidebarAnimateRef={sidebarScope}
                sidebarClassName="sub-user__sidebar__nobutton"
            >
                <div className="sub-user-form">
                    <div className="sub-user-form-input">
                        <Input required={true} name="firstname" className="mb-5" showError={!isValidFields.firstname} label={I18n.t("loginForm.firstname")} placeholder={I18n.t("loginForm.firstname")} onChange={updateField} />
                        <Input required={true} name="lastname" className="mb-5" showError={!isValidFields.lastname} label={I18n.t("loginForm.lastname")} placeholder={I18n.t("loginForm.lastname")} onChange={updateField} />
                        <Input required={true} name="company" showError={!isValidFields.company} label={I18n.t("loginForm.company")} placeholder={I18n.t("loginForm.company")} onChange={updateField} />
                        <div className="mt-50">
                            <Input pattern={passwordRegExpString} required={true} name="password" showError={!isValidFields.password} label={I18n.t("loginForm.passwordLabel")} placeholder={I18n.t("loginForm.passwordLabel")} type="password" onChange={updateField} />
                            <div className="mt-5">
                                <PasswordHint password={body.password} color="main" titleType="info" hintType="info" />
                            </div>
                        </div>
                    </div>
                    <div className="sub-user-form-buttons">
                        <Button label={ I18n.t("subUser.update.submit") } disabled={disabledSubmit} onClick={confirmUpdate} />
                    </div>
                </div>
            </SubUserForm> : <></>
        }
    </>
}

export default connect(null, { isAuthenticated, addFlashMessage, isSubUser })(UpdateProfile);