import React, { useRef } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Text } from "@capps/laddition-storybook";
import { I18n, Translate } from "react-redux-i18n";

export function SubUserForm({ children, backButton, backOnClick, sideTitle, sideDescription, formTitle, animateRef, sidebarAnimateRef, sidebarClassName, contentClassName }) {

    const screenWidth = useRef(window.innerWidth);
    const isTabletPortrait = screenWidth.current <= 992;
    const formInitPosition = isTabletPortrait ? { y: "100%" } : { x: "100%" };
    const formAnimation = isTabletPortrait ? { y: 0 } : { x: 0 };
    const isMobilePortrait = screenWidth.current <= 450;
    const navigate = useNavigate();

    const backButtonOnClick = (e) => {
        if (!backOnClick) {
            return navigate('/');
        }
        return backOnClick(e);
    }



    return <div className="sub-user__step">
        <motion.div className={`${sidebarClassName} sub-user__sidebar`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} ref={sidebarAnimateRef} transition={{ duration: 0.5, delay: 0.35 }}>
            {
                backButton &&
                <Button
                    color={ isTabletPortrait ? "main" : "ldd-grey-100" }
                    variant="text"
                    className="back-button"
                    size={isMobilePortrait ? "small" : "medium" }
                    onClick={backButtonOnClick}
                >
                    <Icon name="chevronleft" color={ isTabletPortrait ? "main" : "ldd-grey-100" } />
                    {I18n.t('subUser.returnMenu')}
                </Button>
            }
            <div className={`${contentClassName} sub-user__sidebar__content`}>
                <div className="sub-user__sidebar__content__title">
                    <Icon name="info" color="white" size="medium"/>
                    <Text color="white" >{I18n.t(sideTitle)}</Text>
                </div>
                <Text type={!isMobilePortrait ? "info" : "label"} color="ldd-grey-100">
                    <Translate value={sideDescription} dangerousHTML />
                </Text>
            </div>
        </motion.div>
        <motion.div className="sub-user__step__content" ref={animateRef} initial={formInitPosition} animate={formAnimation} transition={{ duration: 0.4, ease: "easeInOut" }}>
            <Text className="sub-user-home-title" variant="h3">{I18n.t(formTitle)}</Text>
            { children }
        </motion.div>
    </div>
}

SubUserForm.propsTypes = {
    children: PropTypes.element.isRequired,
    backButton: PropTypes.bool,
    backOnClick: PropTypes.func,
    sideTitle: PropTypes.string.isRequired,
    sideDescription: PropTypes.string.isRequired,
    formTitle: PropTypes.string.isRequired,
    animateRef: PropTypes.object,
    sidebarClassName: PropTypes.string,
    contentClassName: PropTypes.string,
}

SubUserForm.defaultProps = {
    backButton: true,
    sidebarClassName: "",
    contentClassName: ""
}