import React, { Component } from 'react';

class FeedItem extends Component {
	createMarkup() {
		return { __html: this.props.item.text };
	}
	render() {
		return (
			<div className="cd-timeline-block">
				<div className="cd-timeline-img cd-movie"></div>

				<div className="cd-timeline-content">
					<h2>{this.props.item.title}</h2>
					<p dangerouslySetInnerHTML={this.createMarkup()} />
					<span className="cd-date">
						{new Intl.DateTimeFormat('fr-FR', {
							//year: '2-digit',
							month: '2-digit',
							day: '2-digit',
							hour: '2-digit',
							timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						}).format(new Date(this.props.item.date))}
					</span>
				</div>
			</div>
		);
	}
}

export default FeedItem;
