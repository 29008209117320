import axios from 'axios';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import {
	SET_CURRENT_USER,
	SET_RESET_EMAIL_SEND,
	SET_RESET_TOKEN,
	SET_PASSWORD_CHANGED,
	SET_SERVICES, SET_IS_SUB_USER,
} from '../constant';
import getAllUrlParams from '../../utils/getUrlParams';
import { addFlashMessage } from '../../flashMessage/actions/flashMessages';
import { I18n } from 'react-redux-i18n';

export function setCurrentUser(user) {
	return {
		type: SET_CURRENT_USER,
		user,
	};
}

export function setResetEmailSend(success, err, code = null) {
	return {
		type: SET_RESET_EMAIL_SEND,
		success,
		error: err,
		code
	};
}

export function setPasswordChanged(success) {
	return {
		type: SET_PASSWORD_CHANGED,
		success,
	};
}

export function setResetToken(token) {
	return {
		type: SET_RESET_TOKEN,
		token,
	};
}

export function setServices(services) {
	return {
		type: SET_SERVICES,
		services,
	};
}

export function setIsSubUser(isSubUser) {
	return {
		type: SET_IS_SUB_USER,
		isSubUser,
	};
}

export function isAuthenticated() {
	return (dispatch, getState) => getState().auth.isAuthenticated;
}

export function isSubUser() {
	return (dispatch, getState) => (getState().auth.user && getState().auth.user.type === 'sub_user_laddition');
}

export function logout() {
	return dispatch => {
		return axios
			.post('/api/auth/logout', { refresh_token: localStorage.refreshToken }, { withCredentials: true })
			.then(res => {
				if (res.data.success) {
					localStorage.clear();
					setAuthorizationToken(false);
					dispatch(setCurrentUser({}));
				} else {
					dispatch(
						addFlashMessage({
							type: 'error',
							text: I18n.t('logout.error'),
						})
					);
				}
			});
	};
}

// export function getServices() {
//     return dispatch => {
//         return axios.get('/api/service').then(res => {
//             if (res.data.success) {
//                 dispatch(setServices(res.data.services));
//             } else {
//                 dispatch(addFlashMessage({
//                     type: 'error',
//                     text: 'Erreur lors de la recuperation des services'
//                 }));
//             }
//         });
//     }
// }

export function login(data) {
	return dispatch => {
		const { scope } = getAllUrlParams();
		if (scope) {
			data.scope = scope;
		} else {
			//si pas de scope on retourne vers le reporting
			data.scope = false;
		}
		return axios.post('/v2/api/auth', data).then(res => {
			const refreshToken = res.data.refreshToken;
			const back = res.data.back;
			if (res.data.success) {
				localStorage.setItem('refreshToken', refreshToken);
				setAuthorizationToken(refreshToken);;
				dispatch(setIsSubUser(res.data.isSubUser === true));
				dispatch(setCurrentUser(jwtDecode(refreshToken)));
				// dispatch(addFlashMessage({
				//     type: 'success',
				//     text: 'Succès, Redirection sur le reporting l\'addition'
				// }));
				if (back) {
					if (res.data.dbStatus) {
						return {
							dbStatus: res.data.dbStatus,
							back,
						};
					}
					return {
						back,
					};
				}
			} else {
				throw res;
				/*  if (res.data.errors){
                    dispatch(addFlashMessage({
                        type: 'error',
                        text: 'Vous n\'avez pas accés à ce service'
                    }));
                } else {
                    dispatch(addFlashMessage({
                        type: 'error',
                        text: 'Vous n\'avez pas accés à ce service'
                    }));
                } */
			}
		});
	};
}

export function changePassword(data) {
	return dispatch => {
		return axios.post('/api/auth/changePassword', data).then(res => {
			if (res.data.success) {
				dispatch(setPasswordChanged(res.data.success));
			} else {
				dispatch(setPasswordChanged(res.data.success));
			}
		});
	};
}

export function sendVerificationEmail(data) {
	return dispatch => {
		return axios.post('/api/send-verification-email', data).then(res => {
			if (res.data.success) {
				return dispatch(setResetEmailSend(res.data.success, null, null));
			} else {
				return dispatch(setResetEmailSend(res.data.success, res.data.message, res.data.code));
			}
		});
	};
}

export function checkResetTokenValidity(resetToken) {
	return dispatch => {
		const data = {
			resetToken: resetToken,
		};
		return axios.post('/api/check-reset-token-validity', data).then(res => {
			if (res.data.success) {
				dispatch(setResetToken(res.data.token));
			}
			res.data.resetToken = resetToken;
			return res.data;
		});
	};
}

export function verifySubUser({ email , sub_user_id }) {
	return (dispatch) => {
		return axios.get(`/v2/api/sub-user/verify/${email}/${sub_user_id}`).then(({ data }) => {
			const { refreshToken, enabled } = data;
			if (refreshToken) {
				localStorage.setItem("refreshToken", refreshToken);
				setAuthorizationToken(refreshToken);
				dispatch(setCurrentUser(jwtDecode(refreshToken)));
				return enabled;
			}
		});
	}
}