import React from "react";
import Proptypes from "prop-types";
import classnames from "classnames";
import "./PasswordHint.css";
import { I18n } from "react-redux-i18n";
import {Icon, Text} from "@capps/laddition-storybook";

const PasswordHint = ({
  color = "white",
  titleType= "cta",
  hintType = "label",
  password,
  className,
  colorSuccess,
  colorError,
  ...props
}) => {
  return (
    <div className={`${className} password__hint__container`}>
      <Text color={color} type={titleType} className="mb-0">{I18n.t("subUser.update.password")}</Text>
      <section className="password__hint__check">
        <PasswordRequirement regex=".{8,}" text={I18n.t("tooltip.passwordHint.nbCharacters")} type={hintType} value={password} colorSuccess={colorSuccess} colorError={colorError} className="password__hint__item mb-0" />
        <PasswordRequirement regex=".*[a-z]" text={I18n.t("tooltip.passwordHint.lowercase")} type={hintType} value={password} colorSuccess={colorSuccess} colorError={colorError} className="password__hint__item mb-0" />
        <PasswordRequirement regex=".*[A-Z]" text={I18n.t("tooltip.passwordHint.uppercase")} type={hintType} value={password} colorSuccess={colorSuccess} colorError={colorError} className="password__hint__item mb-0" />
        <PasswordRequirement regex=".*[0-9]" text={I18n.t("tooltip.passwordHint.numerical")} type={hintType} value={password} colorSuccess={colorSuccess} colorError={colorError} className="password__hint__item mb-0" />
        <PasswordRequirement regex="[^A-Za-z0-9]" text={I18n.t("tooltip.passwordHint.special")} type={hintType} value={password} colorSuccess={colorSuccess} colorError={colorError} className="password__hint__item mb-0" />
      </section>
    </div>
  );
};

function PasswordRequirement ({ text, regex, value, type, className, colorSuccess, colorError }) {
  const isValid = new RegExp(regex, 'g').test(value);
  const successColor = colorSuccess ?? 'ldd-green-950';
  const errorColor = colorError ?? 'ldd-error-950';

  const color = isValid ? successColor : errorColor;
  const iconName = isValid ? 'check' : 'invalid';
  return <Text type={type} color={color} className={className}>
    <Icon
        name={iconName}
        color={color}
        size="small"
        style={{ marginRight: '10px',  top: '4px' }}
    />
    { text }
  </Text>;
}

PasswordHint.PropTypes = {
  nbCharIsOk: Proptypes.bool.isRequired,
  nbUpperIsOk: Proptypes.bool.isRequired,
  nbLowerIsOk: Proptypes.bool.isRequired,
  nbSpecialCharIsOk: Proptypes.bool.isRequired
};

PasswordHint.defaultProps = {
  nbCharIsOk: false,
  nbUpperIsOk: false,
  nbLowerIsOk: false,
  nbSpecialCharIsOk: false
};

export default PasswordHint;
