import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, isSubUser, logout } from "../actions/authActions";
import { connect } from "react-redux";
import {Icon, Loader, Select} from "@capps/laddition-storybook";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { I18n } from "react-redux-i18n";
import getAllUrlParams from "../../utils/getUrlParams";
import { addFlashMessage } from "../../flashMessage/actions/flashMessages";
import { Layer, Grid, LinkedCard, Paragraph, Title, Stack, IconButton } from "@capps/cudl";
import { ArrowLeft, LogOut } from "lucide-react";

function LoginAsPage (props) {

  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("logAsRefreshToken");
  const payload = refreshToken ? jwtDecode(refreshToken) : {};

  const [parents, setParents] = useState([]);
  const [username, setUsername] = useState("");
  const [currentParent, setCurrentParent] = useState(payload.connected_as ? payload.id : "");
  const currentParentInfos = parents.find((p) => p.id === +currentParent) || {};
  const [services, setListServices] = useState([]);
  const [isSmallScreen, setSmallScreen] = useState(window.innerWidth < 768);
  const { state } = useLocation();
  const getParams = getAllUrlParams();


  useEffect(() => {
    if (!props.isAuthenticated() || !props.isSubUser()) {
      navigate('/');
      return;
    }

    if(!!payload) {
      startUpCalls()
        .catch((err) => {
          if(err.status === 403 || err.status === 401) {
            props.addFlashMessage({
              type: "error",
              text: I18n.t("errorUnknown")
            });
            navigate('/logout');
          }
        });
    }
  }, []);

  window.addEventListener('resize', () => {
    setSmallScreen(window.innerWidth < 768)
  });

  const startUpCalls = async () => {
    await getUserInfo();
    await getSubUserServices();
    await getParentsList();
  }

  const getParentsList = () => {
    return axios
        .get("/v2/api/sub-user/parents")
        .then((res)=> {
          setParents(res.data.parentUsers)
          if (res.data.parentUsers.length === 1) {
            setCurrentParent(res.data.parentUsers[0].id);
          }
        })
        .catch((err) => {
          throw err.response;
        });
  }

  const getUserInfo = () => {
    axios.get("/v2/api/user/infos")
      .then((result) => {
        const { username } = result.data;
        setUsername(username);
      })
      .catch((err) => {
        throw err.response;
      });
  }

  function getSubUserServices() {
    axios.get("/v2/api/group/sub_user/services")
      .then((result) => setListServices(result.data))
      .catch((err) => console.error(err));
  }

  const connectAs = (scope) => {
    return axios.post("/v2/api/sub-user/log-subuser-as", { parent_id: currentParent, scope }).then((res) => {
      const data = res.data.result;
      localStorage.setItem("logAsRefreshToken", data.refreshToken);
      if(!!data.back) {
        window.location.href = data.back.url + "?refreshToken=" + data.refreshToken;
      }
    }).catch((err) => {
      const code = err.response.status;
      if (code === 403) {
        props.addFlashMessage({
          type: "error",
          text: I18n.t("subUser.logAs.error")
        });
      } else {
        props.addFlashMessage({
          type: "error",
          text: I18n.t("errorUnknown")
        });
      }
    });
  }

  const goBack = () => {
    if (currentParent) {
      connectAs();
    }
  }

  const getIconService = (serviceName) => {
    const iconByService = {
      "espace_client": "restaurant",
      "bo-client": "barchart",
      "mediation": "partneractivation",
      "commande": "chefhat",
      "la-resa": "clock",
      "reservation": "clock",
      "live-laddition": "activity",
      "carte-web": "dish",
      "reporting-pay-plus": "payplus",
    }
    const iconName = iconByService[serviceName];

    return (props) => iconName ? <Icon name={iconName} color="white" width="32" {...props} /> : <></>;
  }

  const logout = async () => {
    await props.logout();
    navigate('/');
  }

  return parents.length > 0 ? <div className="grid w-full h-screen wave-transparent sb-dark" style={{gridTemplateRows: "max-content"}}>
      <Layer vibrant>
        <Stack vertical={isSmallScreen} className="sb-justify-between">
          <div className={isSmallScreen && 'mb-20'}>
            {payload.connected_as && currentParent &&
              <IconButton onClick={goBack} icon={ArrowLeft} size="small" label={I18n.t('subUser.logAs.back')}/>}
            <div>
              <Title>{I18n.t('subUser.logAs.title')} {username.split(' ')[0]}&nbsp;!</Title>
              {parents.length > 1 && <>
                <Paragraph className="mb-20">
                  {I18n.t('subUser.logAs.body')}
                </Paragraph>
                <form>
                  <Select
                    label={I18n.t('subUser.logAs.parentLabel')}
                    className="max-w-[600px]"
                    name="account"
                    value={currentParent}
                    onChange={(value) => setCurrentParent(+value)}
                  >
                    {
                      parents.map((parent) => <option key={parent.id} value={parent.id}>{parent.username}</option>)
                    }
                  </Select>
                </form>
              </>}
              {parents.length === 1 &&
                <Paragraph>{I18n.t('subUser.logAs.singleBody')} {currentParentInfos.username}</Paragraph>}
            </div>
          </div>
          <div className="sb-self-center">
            <IconButton icon={LogOut} label={I18n.t('logout.button')} onClick={logout}/>
          </div>
        </Stack>
      </Layer>
      <Layer dark>
        <Paragraph className="mb-20 mt-0">{I18n.t('subUser.logAs.services')}</Paragraph>
        <Grid columns={{xs: 1, md: 2, xl: 3}} gap={{xs: 4, xl: 6}}>
          {
            services
              .filter((service) => service.hidden === 0)
              .map((service) => {
                const disabled = !currentParentInfos.services?.some((s) => s.name === service.name);
                return <LinkedCard
                  key={service.name}
                  label={service.title}
                  disabled={disabled}
                  className={disabled && "no-pointer"}
                  icon={getIconService(service.name)}
                  onClick={() => connectAs(service.name)}
                />
              })
          }
        </Grid>
      </Layer>
    </div> : <div className="loader__background sb-bg-black/50">
      <Loader/>
    </div>;

}


export default connect(null, {isAuthenticated, isSubUser, addFlashMessage, logout })(LoginAsPage);
