import React from "react";
import { useNavigate } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import {Button, Text} from "@capps/laddition-storybook";


export default function NoMatch() {

  const navigate = useNavigate();

  return (
    <div className="page-not-found">
      <Text variant="h1" weight="bold" color="white">{I18n.t("notFound.title")}</Text>
      <div className="divider"></div>
      <Text color="white" variant="h4" >{I18n.t("notFound.description")}</Text>
      <Button
        variant="contained"
        color="secondary"
        className="notfound__button"
        label={I18n.t("notFound.backLogin")}
        onClick={() => navigate("/")}
      />
    </div>
  );
}
