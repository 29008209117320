import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authAction from "../actions/authActions";
import ResetPasswordFormStep1 from "../components/ResetPasswordFormStep1";
import ResetPasswordFormStep2 from "../components/ResetPasswordFormStep2";
import getUrlParams from "../../utils/getUrlParams";

class ResetPasswordPage extends React.Component {
  render() {
    const { resetToken } = getUrlParams();

    let resetPasswordForm = <ResetPasswordFormStep1 />;

    if (resetToken) {
      resetPasswordForm = <ResetPasswordFormStep2 />;
    }

    return resetPasswordForm;
  }
}
function mapStateToProps(state) {
  return {
    resetToken: state.resetPasswordToken
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage);
