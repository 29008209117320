import React from 'react';
import LoginForm from '../components/LoginForm';
// import classnames from 'classnames';

class LoginPage extends React.Component {
    render() {
        return (
            <LoginForm />
        );
    }
}

export default LoginPage;
