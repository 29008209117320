import React from "react";
import FlashMessagesList from "./flashMessage/containers/FlashMessagesList";
import { connect } from "react-redux";
import { addFlashMessage } from "./flashMessage/actions/flashMessages";
import "./css/login.css";
import { I18n } from "react-redux-i18n";
import FooterLaddition from "./login/components/FooterLaddition";
import AppRouter from "./routes";

class App extends React.Component {
  constructor(props) {
    super(props);
    let localstorageIsAvailable = false;
    try {
      localStorage.setItem("test", "test");
      localStorage.removeItem("test");
      localstorageIsAvailable = true;
    } catch (e) {
      console.log(e);
    }

    this.state = {
      localstorageIsAvailable: localstorageIsAvailable
    };
  }

  componentDidMount() {
    if (!this.state.localstorageIsAvailable) {
      this.props.addFlashMessage({
        type: "error",
        text: I18n.t("errorLocalStore")
      });
    }
  }

  render() {
    const { localstorageIsAvailable } = this.state;

    if (localstorageIsAvailable) {
      return (
        <div className="height100 background content__container">
          <AppRouter />
          <FooterLaddition />
          <FlashMessagesList />
        </div>
        // <div className="container">
        //     <FlashMessagesList />
        //     {this.props.children}
        // </div>
      );
    } else {
      return (
        <div className="background">
          <FooterLaddition />
          <FlashMessagesList />
        </div>
      );
    }
  }
}

export default connect(
  null,
  { addFlashMessage }
)(App);
