import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import setAuthorizationToken from './utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from './login/actions/authActions';
import getAllUrlParams from './utils/getUrlParams';
import axios from 'axios';
import { addFlashMessage } from './flashMessage/actions/flashMessages';
import {
	loadTranslations,
	setLocale,
	syncTranslationWithStore,
} from 'react-redux-i18n';
import langs from './lang';
import { I18n } from 'react-redux-i18n';
import App from "./App";

const { clear } = getAllUrlParams();
if (clear) {
	if (window.localStorage.cacheCleared) {
		window.localStorage.clear();
		document.location.href = '/';
	} else {
		window.localStorage.clear();
		window.localStorage.cacheCleared = true;
		document.location.reload(true);
	}
}

const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(apiMiddleware, thunk),
		window.devToolsExtension ? window.devToolsExtension() : f => f
	)
);

syncTranslationWithStore(store);
const langsArr = Object.keys(langs);
let lang = window.navigator.language || 'fr';
if (langsArr.indexOf(lang) === -1) {
	lang = 'fr';
}
store.dispatch(loadTranslations(langs));
store.dispatch(setLocale(lang));
// axios
//   .get("/api/user/local", {
//     headers: { Authorization: `Bearer ${window.localStorage.accessToken}` }
//   })
//   .then(res => {
//     store.dispatch(setLocale(res.data || "fr"));
//   });

if (localStorage.refreshToken) {
	setAuthorizationToken(localStorage.refreshToken);
	const { scope } = getAllUrlParams();
	const data = {
		token: localStorage.refreshToken,
		scope: scope,
	};

	store.dispatch(setCurrentUser(jwtDecode(localStorage.refreshToken)));
}

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
