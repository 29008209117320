import PropTypes from "prop-types";
import React from "react";
import FlashMessage from "../components/FlashMessage";
import { connect } from "react-redux";
import { deleteFlashMessage } from "../actions/flashMessages";

class FlashMessagesList extends React.Component {
  render() {
    const messages = this.props.messages.map(message => (
      <FlashMessage key={message.id} message={message} />
    ));
    return <div className="flashMessageList">{messages}</div>;
  }
}

FlashMessagesList.propTypes = {
  messages: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    messages: state.flashMessages
  };
}

export default connect(
  mapStateToProps,
  { deleteFlashMessage }
)(FlashMessagesList);
