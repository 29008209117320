import { connect } from "react-redux";
import { isAuthenticated, isSubUser } from "../../actions/authActions";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Button, Icon, Loader, Text, Input} from "@capps/laddition-storybook";
import { SubUserServices } from "./components/Services";
import axios from "axios";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";
import { I18n, Translate } from "react-redux-i18n";
import {motion, useAnimate} from "framer-motion";
import {isValidMail} from "../../../utils/toolbox";
import {SubUserForm} from "./components/SubUserForm";
import {transformErrorMessage} from "../../../utils/errorMessage";

function NewSubUser ({ isAuthenticated, addFlashMessage, isSubUser }) {
    const navigate = useNavigate();

    if(!isAuthenticated() || isSubUser()) {
        setTimeout(() => navigate('/'), 1);
        return <></>;
    }

    const [servicesList, setServices] = useState([]);
    const [servicesActiveList, setActiveServices] = useState([]);
    const [email, setEmail] = useState("");
    const [scope, animate] = useAnimate();
    const [sidebarScope, animateSidebar] = useAnimate();
    const [isLoading, setLoading] = useState(true);


    const screenWidth = useRef(window.innerWidth);
    const isTabletPortrait = screenWidth.current <= 992;
    const formExitAnimation = isTabletPortrait ? { y: "100%" } : { x: "100%" };

    const isValidEmail = isValidMail(email);

    useEffect(() => {
        getServicesUser()
            .then((response) => {
                const services = response.data.services;
                setServices(services.filter((service => !service.hidden)))
            })
            .finally(() => setLoading(false));
    }, [servicesList.length]);

    const getServicesUser = () => axios.get('/v2/api/sub-user/services/list');

    const updateList = (activeList) => setActiveServices(activeList);

    const updateEmail = (event) => {
        const isValid = isValidMail(event.target.value);
        setEmail(event.target.value);
    }

    const submitForm = async () => {
        const body = {
            email,
            services: servicesActiveList,
        };
        return axios.post("/v2/api/sub-user", body)
            .then((response) => {
                addFlashMessage({
                    type: "success",
                    text: `${I18n.t('subUser.new.success')}: ${email}`,
                });
                return backToMenu();
            })
            .catch(({ response }) => {
                const { code, message } = response.data.error;
                const errorCode = `subUser.errorCode.${code}`;
                const text = code ? transformErrorMessage(errorCode, message) : message;
                addFlashMessage({ type: 'error', text });
            })
    }

    const backToMenu = async () =>  {
        animateSidebar(sidebarScope.current, { opacity: 0 }, { duration: 0.35 });
        await animate(scope.current, formExitAnimation, { duration: 0.35 });
        navigate('/sub-user');
    }

    return (servicesList.length > 0 && !isLoading) ?
        <SubUserForm
            animateRef={scope}
            sidebarAnimateRef={sidebarScope}
            backOnClick={backToMenu}
            sideTitle="subUser.new.side.title"
            sideDescription="subUser.new.side.description"
            formTitle="subUser.new.title"
        >
            <div className="sub-user-form">
                <Text style={{ marginBottom: 5 }}>{I18n.t('subUser.new.email')} :</Text>
                <div className="sub-user-form-input">
                    <Input onChange={updateEmail} label="Adresse mail" placeholder="Adresse Mail" type="mail" id="mail"/>
                </div>
                <div className="divider"></div>
                <div className={ !isValidEmail ? "disabled" : ""}>
                    <Text style={{ marginBottom: "10px" }}>{I18n.t('subUser.new.services')} :</Text>
                    {
                        servicesList.length > 0 ?
                            <SubUserServices servicesList={servicesList} onClick={updateList} />
                            : null
                    }
                    <div className="sub-user-form-buttons w-100">
                        <Button color={ isValidEmail && servicesActiveList.length > 0 ? "primary" : "ldd-blue-400" } label={I18n.t('subUser.new.submit')} disabled={servicesActiveList.length < 1} onClick={submitForm} />
                    </div>
                </div>
            </div>
        </SubUserForm> :
        <div className="loader__background">
            <Loader />
        </div>
}

export default connect(null, { isAuthenticated, addFlashMessage, isSubUser })(NewSubUser);