export default {
	maintenance: {
		title: 'Services en maintenance',
		subTitle1:
			'11/03 - 16H : <br/><br/>\
      Suite à l’incendie d’un site de notre hébergeur OVH, nos services sont actuellement fortement perturbés. <br/><br/> \
      OVH doit opérer un certain nombre de réparations dont l’électricité / réseaux qui seront planifiées dès la semaine prochaine. <br/>\
      Caisse enregistreuse -> Non impacté <br/><br/>\
      Reporting, accès aux données -> Service perturbé, non accessible <br/>\
      Click & Collect , service partenaires -> Service perturbé, non accessible <br/><br/>\
      Pour rappel, vous pouvez accéder à l’historique de vos services depuis votre caisse enregistreuse : <br/><br/>\
      Réglages -> Historique de la caisse. <br/><br/>\
      Merci pour votre patience et votre indulgence. <br/>\
      L’équipe L’Addition',
		subTitle2: ' ',
		altMaintenance: 'maintenance',
	},
	tooltip: {
		emailEquals: {
			important: 'Important',
			identical: 'Cet email est identique au précédent',
		},
		emailHint: {
			important: 'Important',
			inputEmail:
				"Je saisis un email valide auquel j'ai accès (un email de vérification sera envoyé à cette adresse)",
			notAssociated: "Cet email n'est pas déjà associé à un compte L'Addition",
		},
		passwordEquals: {
			important: 'Important',
			identical: 'Les mots de passe sont identiques',
		},
		passwordHint: {
			title: 'Mon mot de passe doit comporter au moins :',
			nbCharacters: '8 caractères',
			uppercase: '1 majuscule',
			lowercase: '1 minuscule',
			numerical: '1 chiffre',
			special: '1 caractère spécial',
		},
	},
	errorCode: {
		NOT_SENDING_MAIL: 'Erreur lors de l\'envoi de l\'email de vérification',
		UNKNOWN_USER: 'Cette adresse mail n\'existe pas',
		ACCOUNT_NOT_CONFIRMED: "Votre compte n'a pas été validé.",
		UNKNOWN_TOKEN: "Lien invalide, veuillez réessayer.",
		TOKEN_EXPIRATION: "Lien expiré, veuillez réessayer l'opération.",
		UNKNOWN_ERROR: "Une erreur est survenue",
		ACCOUNT_NOT_READY: "Votre compte n'est pas encore prêt, merci d'attendre la validation de votre compte par mail.",
	},
	header: {
		nameTool: 'Auth',
		nameCompany: "L'Addition",
	},
	errorLocalStore:
		"Un composant requis pour l'authentification n'est pas disponible, veuillez quitter le mode navigation privée ou changer de navigateur.",
	errorUnknown: "Une erreur est survenue, veuillez réessayer ultérieurement.",
	wrongService: "Ce service n'appartient pas à l'Addition.",
	notAllowed: "Vous n'avez pas accès à ce service",
	notAllowedPage: "Vous n'avez pas accès à cette page, veuillez vous connecter.",
	needLogin: 'Vous avez besoin de vous connecter pour accéder à cette page.',
	loginForm: {
		errorIdentifierEmpty: 'Ce champs est requis',
		errorPasswordEmpty: 'Ce champs est requis',
		invalidCredentials: 'Identifiant ou mot de passe incorrect.',
		tooManyWrongRequest: 'Compte bloqué veuillez contacter le SAV.',
		loginChanged:
			'Si vous avez récemment changé vos identifiants, pensez à les utiliser et ne plus vous connecter avec ceux en @laddition.com',
		title: 'Connexion',
		description: "Je souhaite m'authentifier chez ",
		emailLabel: 'Identifiant / Email',
		passwordLabel: 'Mot de passe',
		connection: 'CONNEXION',
		valid: 'Valider',
		forgotPassword: 'Mot de passe oublié ?',
		sponsorship: 'Parrainez un ami et recevez 100 €',
		altSponsorship: 'cadeau parainage',
		successAuthentication: 'Bienvenue ',
		firstname: 'Prénom',
		lastname: 'Nom',
		company: 'Société'
	},
	logout: {
		title: 'Déconnexion en cours',
		error: 'Erreur lors de la déconnexion',
		button: 'Se déconnecter',
	},
	resetPassword1: {
		empty: 'Ce champs est requis',
		successVerification:
			"Votre modification est presque terminée. Vous allez recevoir un email de vérification dans quelques instants. Merci de cliquer sur le bouton présent dans l'email pour finaliser la modification de votre mot de passe.",
		successSupport:
			"Le service technique s'occupe de traiter votre demande dans les plus brefs délais.",
		notConfirmed: "Votre compte n'a pas été validé.",
		noUser: "Cet email n'existe pas.",
		error: 'Une erreur est survenue.',
		title: 'Réinitialiser votre mot de passe',
		emailLabel: 'Identifiant / Email',
		submit: 'Valider',
	},
	resetPassword2: {
		empty: 'Ce champs est requis',
		errorValidator:
			'Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
		unidentical: 'les mots de passes ne sont pas identiques',
		success:
			'Vous pouvez désormais vous connecter avec vos nouveaux identifiants',
		title: 'Saisissez votre nouveau mot de passe',
		passwordLabel: 'Mot de passe',
		passwordConfirmLabel: 'Confirmer mot de passe',
	},
	notFound: {
		title: 'Oups !',
		description: "La page demandée n'a pas pu être trouvée",
		backLogin: "Retourner sur la page d'accueil",
	},
	subUser: {
		returnHome: "Retour à l'accueil",
		returnMenu: "Retour au menu",
		errorCode: {
			ALREADY_GRANTED: "Vous avez déjà accordé l'accès à cet utilisateur",
			EXISTING_CUSTOMER: "Cet adresse mail correspond déjà à un client L'Addition, vous ne pouvez pas lui accorder l'accès",
		},
		home: {
			title: 'Que souhaitez-vous faire ?',
			newLabel: 'Créer un nouvel utilisateur',
			editLabel: 'Gestion des utilisateurs',
		},
		new: {
			title: 'Création d\'un nouvel utilisateur',
			email: 'Saisissez l\'adresse mail du nouvel utilisateur',
			services: 'Le nouvel utilisateur aura accès aux services sélectionnés',
			submit: "Créer Utilisateur",
			success: "Une invitation a été envoyé à l'adresse suivante",
			side: {
				title: 'Un seul compte, plusieurs utilisateurs',
				description: 'Il est désormais possible d\'attribuer plusieurs utilisateurs sur le même compte client.<br/><br/>Pour cela veuillez déterminer les accès que vous autorisez à chacun d\'entre eux.',
			}
		},
		edit: {
			title: 'Gestion des accès utilisateurs',
			email: 'Sélectionnez l’utilisateur pour modifier ses accès',
			services: 'Les services accessibles à l\'utilisateur sélectionné :',
			submit: "Modifier les accès",
			error: "Désolé, il semblerait que vous n'ayez pas encore configuré d'accès à une tierce personne.",
			create: "Créer un nouvel accès utilisateur",
			success: "Les accès ont été modifiés avec succès",
			side: {
				title: 'Modifier les accès utilisateurs',
				description: 'Vos employés évoluent ? Leurs accès également !<br/><br/>Afin de pouvoir toujours coller à vos besoins, vous pouvez à tout moment ajouter ou restreindre les accès de chacun de vos utilisateurs.',
			},
			resend: {
				success: "Le mail a été renvoyé avec succès",
				error: "Erreur lors de l'envoi du mail",
				ALREADY_ACTIVATED: "Cet utilisateur a déjà activé son compte",
			}
		},
		verify: {
			error: {
				"400": "Vous avez déjà activé votre compte, veuillez vous connecter.",
				"404": "Lien invalide, veuillez réessayer."
			}
		},
		activate: {
			valid : {
				title: 'Bienvenue !',
				info: "Votre compte a été lié au compte parent avec succès",
			},
			waiting: {
				title: 'Saisissez votre code secret',
				info: "Un mail a été envoyé sur l'email de l'utilisateur à l'initiative de la demande de création du droit d'accès.",
			},
			error: {
				title: "Code invalide, retentez.",
			},
			resend: {
				label: "Renvoyez l'email contenant le code",
				success: "Renvoi du code secret réalisé avec succès",
				error: "Erreur lors du renvoi du code secret",
			},
			success: "Félicitations, votre liaison a été activée avec succès",
		},
		update: {
			title: "Activation du compte utilisateur",
			password: "Le mot de passe doit respecter les critères suivants",
			submit: "Confirmer inscription",
			success: "Votre compte a été créé avec succès. Vous allez être invité à vous reconnecter.",
			side: {
				title: 'Les informations indispensables',
				description: 'Pour créer un nouveau compte, il est essentiel de renseigner le <b>nom</b> et <b>prénom</b>, ainsi que définir un <b>mot de passe</b>',
			},
			error: {
				updated: "Ja has actualitzat la teva informació.",
			}
		},
		logAs: {
			title: "Bonjour",
			body: "Quel est l’établissement qui vous intéresse aujourd’hui ?",
			singleBody: "Vous êtes connecté à l'établissement :",
			parentLabel: "Listes des établissements",
			services: "Listes des services disponibles",
			submit: "C'est parti !",
			back: "Retour à votre espace",
			error: "L'administrateur de cet établissement ne vous a pas donné les droits d'accéder à ce service.",
		}
	}
};
